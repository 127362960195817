.productDemo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 50px;
    background: linear-gradient(272deg, rgba(255, 0, 198.90, 0.63) 0%, rgba(0, 10.20, 255, 0.63) 100%);
    border-radius: 1rem;
}


.productDemoLeft,
.productDemoRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10%;
    color: var(--background-color);
    gap: 25px;
}


.productDemoTitle {
    font-size: 2rem;
    font-weight: 600;
    word-wrap: break-word;
}

.productDemoSubtitle {
    font-weight: 500;
    font-size: 1rem;
    word-wrap: break-word;
}

.productDemoBtn {
    background-color: #FFB21D;
    color: var(--background-color);
    font-size: 1.2rem;
    border: none;
    border-radius: 0.5rem;
    padding: 0.7rem 1.2rem 0.7rem 1.2rem;
    cursor: pointer;
}


@media(max-width: 650px) {
    .productDemoRight {
        display: none;
    }

    .productDemoLeft {
        grid-column: 1/3;
    }
}