.productDetailsFormContent {
    display: flex;
    flex-direction: column;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 40px;
    gap: 30px;
}

.productDetailsFormContent>.container {
    display: flex;
    flex-direction: row;
}

.productDetailsForm {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;
}

.productDetailsForm>label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    font-weight: 600;
}


.adminPanelProductLogo {
    width: 100px;
    height: 100px;
}


.productDetailsFormContent>.container>.right {
    margin-left: 5vw;
    display: flex;
    flex-direction: column;
}

.productDetailsFormContent>.container>.right>.logo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 1.2rem;
    font-weight: 600;
    height: 200px;
}

.adminPanelProductImageContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    flex-flow: row wrap;
    gap: 10px;
}

.adminPanelProductImage {
    width: 150px;
    height: 150px;
}

.addProductBtn {
    background-color: #3BAE8C;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.updateRequestContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
}


