.askQues {
    display: grid;
    grid-template-columns: 4fr 6fr;
    justify-content: center;
    align-items: center;
    height: 350px;
    max-width: 100%;
    background-color: rgba(0, 86.70, 255, 0.07);
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.10);
    gap: 2vw;
    margin-left: 8vw;
    margin-right: 8vw;
    margin-bottom: 100px;
    border-radius: 1rem;
}

.askQuesLeft {
    width: 80%;
    margin-left: 3vw;
}

.askQuesRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}

.askQuesRight>div>span:nth-child(1) {
    letter-spacing: 0.64;
    font-size: 1.5rem;
    font-weight: 600;
}

.askQuesRight>div>span:nth-child(3) {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 30px;
}

.askQuesRight>textarea {
    width: 80%;
    border: 0.50px rgba(0, 0, 0, 0.27) solid;
    border-radius: 0.5rem;
    outline: none;
    padding: 1rem;
}

.askQuesRight>button {
    font-size: 1.1rem;
    font-weight: 600;
    color: #0057FF;
    background-color: var(--background-color);
    border: 0.1rem solid #0057FF;
    border-radius: 0.4rem;
    padding: 1rem;
    cursor: pointer;
}

