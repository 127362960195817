.productSpec {
    display: flex;
    flex-direction: column;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 60px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    padding: 30px 50px 30px 50px;
    gap: 2rem;
}

.productSpec>.title {
    font-size: 2rem;
    font-weight: 600;
}

.productSpec>.mainContainer {
    display: flex;
    flex-direction: row;
}

.productSpec>.mainContainer>.left {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    font-weight: 600;
    font-size: 0.9rem;
    background-color: #F2F2F3;
    padding: 2rem;
}

.productSpec>.mainContainer>.right {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    font-weight: 500;
    font-size: 0.9rem;
    padding: 1rem;
}

.productSpec>.mainContainer>.right>span>img {
    width: 1rem;
}


