.searchBasedProducts {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: rgba(73.74, 222.37, 255, 0.14);
    gap: 1.5rem;
    margin-left: 8vw;
    margin-right: 8vw;
    margin-bottom: 8rem;
    border-radius: 1rem;
}

.searchBasedProductsTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 3vw;
    margin-right: 3vw;
    margin-top: 1.5rem;
}

.searchBasedProductsTitle {
    font-size: 1.2rem;
    font-weight: 600;
    word-wrap: break-word;
}

.searchBasedProductsRight {
    color: rgba(0, 0, 0, 0.47);
    font-weight: 600;
    word-wrap: break-word;
    cursor: pointer;
}

.searchBasedProductsContent {
    margin-left: 3vw;
    margin-right: 3vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2vw;
    margin-bottom: 2rem;
}

.searchBasedProductsContainer {
    /* height: 300px; */
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
    justify-content: space-around;
    align-items: center;
    background-color: var(--background-color);
    border: 0.50px rgba(0, 0, 0, 0.27) solid;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.searchBasedProductsLogo {
    width: 30%;
    height: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    margin-top: 2rem;
    cursor: pointer;
}

.searchBasedProductsMid {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.searchBasedProductsName {
    font-size: 1rem;
    font-weight: 600;
    word-wrap: break-word;
    cursor: pointer;
}

.searchBasedProductsCompany {
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    word-wrap: break-word;
    text-align: center;
}

.searchBasedProductsRating {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 5px;
}


.searchBasedProductsRating>div:nth-child(1) {
    background-color: #3bae5f;
    color: var(--background-color);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 30%;
    padding: 0.3rem;
    border-radius: 0.3rem;
}

.searchBasedProductsRating>div:nth-child(2) {
    background-color: rgb(221, 221, 221);
    color: var(--color);
    display: flex;
    align-items: center;
    padding: 0.3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

.searchBasedProductsBtn {
    background-color: #FFB21D;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    width: 80%;
    color: var(--background-color);
    font-weight: 600;
    font-size: 1.1rem;
    padding: 0.7rem;
    border: none;
    word-wrap: break-word;
    cursor: pointer;
    margin-bottom: 1rem;
}


@media(max-width: 1000px) {
    .searchBasedProductsContent {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media(max-width: 600px) {
    .searchBasedProductsContent {
        grid-template-columns: 1fr 1fr;
    }
}
