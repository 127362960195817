.displayReview {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    background: #004453;
    color: var(--background-color);
    padding: 2rem 0rem;
}

.displayReviewTitle {
    font-size: 1.2rem;
    font-weight: 600;
    word-wrap: break-word;
    color: var(--background-color);
    margin-left: 8vw;
    margin-top: 2rem;
}

.displayReviewContent {
    display: grid;
    grid-template-columns: 0.7fr 8.6fr 0.7fr;
    gap: 2vw;
    justify-content: center;
    align-items: center;
}

.displayReviewArrowLeft {
    margin-left: 3vw;
    border-radius: 50%;
    display: flex;
    align-items: center;
    background: var(--background-color);
}


.displayReviewArrowRight {
    margin-right: 3vw;
    border-radius: 50%;
    display: flex;
    align-items: center;
    background: var(--background-color);
}

.displayReviewArrowLeft:hover,
.displayReviewArrowRight:hover {
    background-color: #FFC702;
}


.displayReviewArrowLeft>img {
    width: 4vw;
}

.displayReviewArrowRight>img {
    width: 4vw;
}


.displayReviewContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    overflow: hidden;
}

.review {
    flex: 1;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
}


.displayReviewProfileImg {
    width: 25%;
}

.displayReviewText {
    word-wrap: break-word;
    font-size: 1.2rem;
}


