.formDataContainer {
    margin-top: 2rem;
    margin-bottom: 4rem;
    margin-left: 5vw;
    margin-right: 5vw;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.formData1 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.formData1 > input {
    min-width: 15%;
    padding: 1rem;
}

.formData1 > select > option {
    font-size: 1.2rem;
}

.formData1 > select {
    padding: 1rem;
}

.formData1 > button {
    background-color: orange;
    border: none;
    padding: 0.6rem 1rem;
    font-size: 1.3rem;
    font-weight: 500;
    color: #ffffff;
    border-radius: 0.5rem;
    cursor: pointer;
}

.formData2 {
    width: 100%;
}

td,
th {
    border: 0.1rem solid rgb(189, 189, 189);
    padding: 1rem;
}

table {
    border-collapse: collapse;
}
