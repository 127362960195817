.sellProduct {
    display: grid;
    grid-template-columns: 5.5fr 4.5fr;
    margin-left: 8vw;
    margin-right: 8vw;
    margin-top: 40px;
    margin-bottom: 40px;
}


.sellProductRight {
    width: 90%;
}

.sellProductLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.sellProductLeft>span:nth-child(1) {
    font-size: 2rem;
    font-weight: 500;
    color: var(--color);
    word-wrap: break-word;
}


.sellProductLeft>span:nth-child(3) {
    font-size: 2.3rem;
    font-weight: 700;
    color: #E23D2C;
    word-wrap: break-word;
}

.sellProductLeft>button {
    color: #E23D2C;
    background-color: var(--background-color);
    border: 0.15rem solid #E23D2C;
    border-radius: 0.7rem;
    padding: 1rem;
    font-weight: 600;
    margin-top: 30px;
    cursor: pointer;
}

