.serviceTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 5rem;
    font-size: 1.15rem;
    font-weight: 600;
}

.serviceTitle > div {
    word-wrap: break-word;
}

@media (max-width: 600px) {
    .serviceTitle {
        font-size: 1rem;
    }
}

@media (max-width: 400px) {
    .serviceTitle {
        font-size: 0.9rem;
    }
}
