.heroSection {
    width: 100%;
    background: linear-gradient(180deg, #413682 0%, #7d6ce4 100%);
    display: flex;
    margin-top: calc(80px + 3rem);
    padding: 2rem 0rem;
    justify-content: center;
    align-items: center;
}

.heroLeft {
    margin-left: 5vw;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.heroRight {
    margin-right: 5vw;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.heroTitle {
    color: var(--background-color);
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 3rem;
    height: 8rem;
    word-wrap: break-word;
}

.heroInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    gap: 0;
}

.heroInput {
    width: 60%;
    padding: 1rem;
    font-weight: 500;
    color: #898989;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-style: none;
    border: none;
    cursor: text;
    outline-style: none;
}

.heroSearchBtn {
    width: 20%;
    color: var(--background-color);
    font-weight: 600;
    word-wrap: break-word;
    font-size: 1.2rem;
    background-color: #ffc702;
    border: none;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    cursor: pointer;
}

.heroRight>img {
    width: 80%;
}


@media(max-width: 600px) {

    .heroSection {
        justify-content: center;
        align-items: center;
    }

    .heroLeft {
        width: 80%;
        gap: 1rem;
        text-align: start;
    }

    .heroRight {
        display: none;
    }
}

