.orderContent {
    margin-top: calc(80px + 20px + 3rem);
    margin-bottom: 3rem;
    margin-left: 5%;
    margin-right: 5%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10%;
    margin-bottom: 20rem;
}


.box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* height: 5rem; */
    box-shadow: 0px 1px 4px 0px rgb(0, 0, 0.15);
    padding: 1rem 2rem;
    gap: 2rem;
    border-radius: 0.5rem;
}

@media(max-width: 600px) {
    .orderContent {
        grid-template-columns: 1fr;
    }
}

.box>img {
    width: 4rem;
    height: 4rem;
    z-index: -1;
}

.box>div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}