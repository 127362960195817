.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99995;
    transition: opacity 0.3s ease-in-out;
}


.modal-content {
    background-color: white;
    width: 50vw;
    height: 70vh;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}




.toggle-text {
    cursor: pointer;
    text-align: center;
    color: #888;
    margin-top: 10px;
    font-size: 1rem;
}


.modalinput,
.modalBtn {
    width: 75%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.modalBtn {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.modalBtn:hover {
    background-color: #0056b3;
}


.customPhoneInput {
    width: 75%;
    display: flex;
    justify-content: flex-start;
    margin-left: -2%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.registerModalTitle {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 20px;
}


.toastify {
    z-index: 99996;
}


@media (max-width: 600px) {
    .modal-content {
        width: 100%;
    }

    .customPhoneInput {
        margin-left: -5%;
    }
}


@media (max-width: 400px) {

    .customPhoneInput {
        width: 65%;
        margin-left: -15%;
    }
}

