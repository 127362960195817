.adminNavbar {
    height: 80px;
    width: 100%;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.adminNavbar > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 5vw;
    margin-right: 5vw;
}

.adminPanelHomepage {
    font-size: 1.5rem;
    font-weight: 600;
    background-color: #3BAE8C;
    cursor: pointer;
    padding: 0.5rem;
    color: white;
    border-radius: 0.5rem;
}


.adminPanelProductpage {
    font-size: 1.5rem;
    font-weight: 600;
    background-color: #0057FF;
    cursor: pointer;
    padding: 0.5rem;
    color: white;
    border-radius: 0.5rem;
}


.adminPanelFormData {
    font-size: 1.5rem;
    font-weight: 600;
    background-color: #FFB21D;
    cursor: pointer;
    padding: 0.5rem;
    color: white;
    border-radius: 0.5rem;
}


