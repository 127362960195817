.popularCategories {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    justify-content: center;
    margin-top: 1.7rem;
    margin-bottom: 5rem;
}

.popularCategoriesTopContainer {
    display: flex;
    justify-content: space-between;
    margin-left: 8vw;
    margin-right: 8vw;
}

.popularCategoriesTitle {
    font-size: 1.3rem;
    font-weight: 600;
    word-wrap: break-word;
}

.popularCategoriesRight {
    color: rgba(0, 0, 0, 0.47);
    font-weight: 600;
    word-wrap: break-word;
}

.popularCategoriesContent {
    display: grid;
    width: 100%;
    grid-template-columns: 0.7fr 8.6fr 0.7fr;
    gap: 2vw;
    align-items: center;
}

.popularCategoriesArrowLeft {
    margin-left: 3vw;
    border-radius: 50%;
    display: flex;
    align-items: center;
    background: #D9D9D9;
}

.popularCategoriesArrowLeft:hover,
.popularCategoriesArrowRight:hover {
    background-color: #3BAE8C;
}

.popularCategoriesArrowLeft>img {
    width: 4vw;
}

.popularCategoriesArrowRight>img {
    width: 4vw;
}

.popularCategoriesArrowRight {
    margin-right: 3vw;
    border-radius: 50%;
    display: flex;
    align-items: center;
    background: #D9D9D9;
}


.popularCategoriesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    gap: 20px;
    margin-top: 20px;
}

.imageContainer {
    flex: 1;
    min-width: 19vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
}

.imageContainer>img {
    width: 100%;
    border-radius: 1rem;
}

.popularCategoriesImgText {
    position: absolute;
    top: 70%;
    /* left: 55%; */
    transform: translate(-0%, -40%);
    background-color: rgba(0, 0, 0, 0.5);
    color: var(--background-color);
    padding: 10px;
    border-radius: 5px;
    font-size: 1.2rem;
    word-wrap: break-word;
}


