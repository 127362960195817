.topSellingProducts {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    background-color: rgba(255, 227.74, 131.11, 0.36);
    gap: 1.5rem;
    margin-left: 8vw;
    margin-right: 8vw;
    margin-bottom: 8rem;
    border-radius: 1rem;
}

.topSellingProductsTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 3vw;
    margin-right: 3vw;
    margin-top: 1.5rem;
}

.topSellingProductsTitle {
    font-size: 1.2rem;
    font-weight: 600;
    word-wrap: break-word;
}

.topSellingProductsRight {
    color: rgba(0, 0, 0, 0.47);
    font-weight: 600;
    word-wrap: break-word;
    cursor: pointer;
}

.topSellingProductsContent {
    margin-left: 3vw;
    margin-right: 3vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2vw;
    margin-bottom: 2rem;
}

.topSellingProductsContainer {
    /* height: 300px; */
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
    justify-content: space-around;
    align-items: center;
    background-color: var(--background-color);
    border: 0.50px rgba(0, 0, 0, 0.27) solid;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.topSellingProductsLogo {
    width: 30%;
    height: auto;
    margin-top: 2rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.topSellingProductsMid {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.topSellingProductsName {
    font-size: 1rem;
    font-weight: 600;
    word-wrap: break-word;
    cursor: pointer;
}

.topSellingProductsCompany {
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    word-wrap: break-word;
    text-align: center;
}

.topSellingProductsRating {
    display: flex;
    width: 70%;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 5px;
}


.topSellingProductsRating>div:nth-child(1) {
    background-color: #3bae5f;
    color: var(--background-color);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 30%;
    padding: 0.3rem;
    border-radius: 0.3rem;
}

.topSellingProductsRating>div:nth-child(2) {
    background-color: rgb(221, 221, 221);
    color: var(--color);
    display: flex;
    align-items: center;
    padding: 0.3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

.topSellingProductsBtnBox {
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.topSellingProductsBtn1 {
    background-color: #FFB21D;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    width: 100%;
    color: var(--background-color);
    font-weight: 600;
    font-size: 0.9rem;
    padding: 0.7rem;
    border: none;
    word-wrap: break-word;
    cursor: pointer;
}

/* .topSellingProductsBtn2 {
    background-color: #3BAE8C;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    width: 45%;
    color: var(--background-color);
    font-weight: 600;
    font-size: 0.9rem;
    padding: 0.7rem;
    border: none;
    word-wrap: break-word;
    cursor: pointer;
} */


@media(max-width: 1000px) {
    .topSellingProductsContent {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media(max-width: 600px) {
    .topSellingProductsContent {
        grid-template-columns: 1fr 1fr;
    }
}
