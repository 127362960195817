.productDetails {
    margin-top: calc(80px + 3rem + 20px);
    /* height: 600px; */
    margin-left: 5vw;
    margin-right: 5vw;
    display: grid;
    grid-template-columns: 4fr 6fr;
    gap: 2vw;
}

.productDetailsLeft {
    display: flex;
    flex-direction: column;
    max-width: 50vw;
    grid-column: 1/2;
}

.productDetailsImgMainContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.productDetailsImgArr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 15%;
    gap: 0.7rem;
    cursor: pointer;
}


.productDetailsImgSlider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    width: 80%;
    position: relative;
}

.productDetailsArrowLeft {
    position: absolute;
    left: 1%;
    background-color: #BABABA;
    border-radius: 50%;
}

.productDetailsArrowLeft > img {
    width: 3rem;
}

.productDetailsArrowRight > img {
    width: 3rem;
}

.productDetailsArrowRight {
    position: absolute;
    right: 1%;
    background-color: #BABABA;
    border-radius: 50%;
}

.productDetailsImgContainer {
    width: 100%;
    min-height: 28rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.productDetailsMainImg {
    width: 75%;
}


.productDetailsBtn {
    display: flex;
    justify-content: space-between;
}

.productDetailsBtn>button {
    width: 45%;
    margin-top: 20px;
    color: var(--background-color);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 0.5rem;
    padding: 1rem;
    font-size: 1.3rem;
    cursor: pointer;
}

.productDetailsBtn>button:nth-child(1) {
    background-color: #FFB21D;
}

.productDetailsBtn>button:nth-child(2) {
    background-color: #3BAE8C;
}



.productDetailsRight {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    grid-column: 2/3;
}

.productDetailsRightTop {
    display: flex;
    height: 50%;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.2rem;
}


.productDetailsRightTop>span:nth-child(1) {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.64);
}

.productDetailsRightTop>span:nth-child(2) {
    font-size: 1.1rem;
    font-weight: 600;
    word-wrap: break-word;
}

.productDetailsRightTop>span:nth-child(3) {
    font-size: 1rem;
    font-weight: 500;
    word-wrap: break-word;
}

.productDetailsratingContainer {
    display: flex;
    flex-direction: row;
    gap: 10%;
    justify-content: space-between;
    margin-right: 20%;
}

.productDetailsratingContainer>div:nth-child(1) {
    background-color: #3BAE8C;
    color: var(--background-color);
    padding: 0.2rem 1rem 0.2rem 1rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
}

.productDetailsratingContainer>div:nth-child(2),
.productDetailsratingContainer>div:nth-child(3) {
    color: #0057FF;
    font-weight: 500;
    word-wrap: break-word;
    cursor: pointer;
}

.productDetailsPrice {
    font-weight: 600;
    font-size: 1.2rem;
    word-wrap: break-word;
}

.productDetailsPriceLite {
    font-size: 1rem;
    font-weight: 500;
    margin-top: -0.8rem;
}

.productDetailsBottomBtn {
    display: flex;
    gap: 3vw;
}

.productDetailsBottomBtn>button {
    width: 35%;
    background-color: var(--background-color);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.20);
    border: 0.50px #C9A00E solid;
    border-radius: 0.5rem;
    color: #FFB21D;
    padding: 0.5rem;
    font-size: 1.1rem;
    text-align: center;
    cursor: pointer;
}

.productDetailsBottomBtn>button>img {
    margin-right: 2vw;
}

.productDetailsRightBottom {
    display: flex;
    height: 50%;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 100px;
}

.productDetailsRightBottom>div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.productDetailsRightBottom>div>span:nth-child(1) {
    font-weight: 600;
}

.productDetailsRightBottom>div>span:nth-child(2) {
    font-weight: 500;
    word-wrap: break-word;
}

/* .PercIcon {
    margin-right: 20vw;
} */

.tc {
    font-weight: 600;
}


.productDetailsDesc {
    font-size: 1rem;
    line-height: 1.5rem;
}


@media(max-width: 600px) {

    .productDetailsBottomBtn {
        display: none;
    }

    .productDetailsLeft {
        display: none;
    }

    .productDetailsRight {
        grid-column: 1/3;
    }

    .dimensionBox {
        margin-bottom: -10rem;
    }
}

