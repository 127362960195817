.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color);
    color: var(--background-color);
    max-width: 100%;
    padding: 2rem 2rem 4rem 2rem;
    gap: 2rem;
}


.footer1 > a >img {
    width: 3rem;
    height: 3rem;
    border-radius: 5px;
}

.footer1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
}

.footer2 {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    font-size: 0.9rem;
}


.footer2 > span {
    cursor: pointer;
}

