.productDetailsDescSection {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5vw;
    margin-right: 5vw;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
    margin-bottom: 50px;
    margin-top: 70px;
    padding: 2rem 3rem 2rem 3rem;
    gap: 25px;
    font-size: 1rem;
    line-height: 1.5rem;
}

.productDetailsDescSection > .title {
    font-size: 1.5rem;
    font-weight: 600;
}

.productDetailsDescSection > .desc {
    color: rgb(35, 35, 35);
    word-wrap: break-word;
}

