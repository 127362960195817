.addReviewContent {
    margin-top: calc(80px + 30px);
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
}

.addReviewContainer1 {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    width: 70%;
}

.addReviewContainer1>span {
    font-size: 1.1rem;
    font-weight: 600;
}

.addReviewContainer1>textarea {
    width: 100%;
    height: 1rem;
    padding: 1.5rem;
}

textarea {
    outline: none;
}

.addReviewContainer2 {
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 1.5rem;
}

.addReviewContainer2Child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.addReviewContainer2Child>div {
    display: flex;
    width: 40%;
    justify-content: space-between;
}

.addReviewContainer2Child>div>span {
    font-weight: 600;
}

.addReviewContainer3 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.addReviewContainer4,
.addReviewContainer5 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 70%;
}

.addReviewContainer4>span,
.addReviewContainer5>span {
    font-size: 1rem;
    font-weight: 600;
}

.addReviewContainer4>textarea,
.addReviewContainer5>textarea {
    width: 100%;
    padding: 1rem;
    height: 4rem;
}

.addReviewBtn {
    padding: 0.7rem 1rem;
    background-color: #F65D35;
    color: var(--background-color);
    border: none;
    border-radius: 0.5rem;
    font-weight: 600;
    font-size: 1.1rem;
    cursor: pointer;
}


.addReviewContainerTop {
    display: flex;
    height: 7rem;
    gap: 2rem;
    align-items: center;
    width: 70%;
}

.addReviewContainerTop>img {
    height: 90%;
}

.addReviewContainerTop>div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.addReviewContainerTop>div>.name {
    font-weight: 600;
    font-size: 1.2rem;
}

.addReviewContainerTop>div>.brand {
    font-size: 0.8rem;
    color: gray;
}

.reviewRecommendBtn {
    width: 2.3rem;
    height: 2.3rem;
    border: 0.05rem solid black;
    border-radius: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reviewRecommendBtnContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    font-size: 0.9rem;
    cursor: pointer;
}

.activeBtn {
    background-color: #462CC2;
    color: var(--background-color);
    border: none;
}


@media (max-width: 600px) {
    .addReviewContainer2Child {
        flex-direction: column;
        gap: 1.5rem;
    }
    
}