.productFeatures {
    display: flex;
    flex-direction: column;
    gap: 50px;
    /* min-height: 300px; */
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 40px;
}

.productFeaturesTitle {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
}


.productFeaturesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 4rem;
    column-gap: 2rem;
}

.productFeaturesContent > .logo {
    width: 2rem;
    height: 2rem;
}

.productFeaturesContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.productFeaturesContent > .desc {
    text-align: center;
    font-size: 0.9rem;
    color: rgb(47, 47, 47);
}

.productFeaturesContent > .title {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    word-wrap: break-word;
}
