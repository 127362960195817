@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');


:root {
  margin: 0 auto;
  --color: #000000;
  --background-color: #ffffff;
}


body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--color);
  max-width: 100vw;
  width: 100%;
}

code {
  font-family: 'Inter', sans-serif;
}


html {
  font-size: 16px;
}


@media (max-width: 800px) {
  
  html {
    font-size: 13px;
  }
}


@media (max-width: 700px) {
  
  html {
    font-size: 12px;
  }
}

@media (max-width: 650px) {
  
  html {
    font-size: 10px;
  }
}

@media (max-width: 550px) {
  
  html {
    font-size: 9px;
  }
}
