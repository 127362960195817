.productReview {
    margin-left: 5vw;
    margin-right: 5vw;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.productReview>.reviewTitle {
    font-size: 1.2rem;
    font-weight: 600;
}

.productReviewContainerTop {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 2.5rem;
    gap: 1rem;
}

.productReviewContainerTop>.container {
    border: 1.5px solid #9C9EA0;
    /* height: 10rem; */
    border-radius: 0.5rem;
    padding: 2rem 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.productReviewContainer1>div {
    width: 50%;
}

.productReviewContainer1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    font-size: 0.85rem;
    font-weight: 600;
    grid-column: 1/2;
    grid-row: 1/3;
}

.productReviewContainer2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    grid-column: 2/4;
    grid-row: 1/3;
}


.productReviewContainer2>div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.productReviewContainer2>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.productReviewContainer2>div>span:nth-child(1) {
    font-size: 1.2rem;
    font-weight: 600;
}

.productReviewContainer2>div>span:nth-child(2) {
    font-size: 1.2rem;
    font-weight: 600;
}

.productReviewContainer2>div>span:nth-child(3) {
    font-size: 1rem;
    font-weight: 600;
}

.productReviewContainer2Child>.grandChild {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    justify-content: center;
    align-items: center;
}

.productReviewContainer2Child>.grandChild>div>span:nth-child(1) {
    font-size: 0.75rem;
    font-weight: 600;
}

.productReviewContainer2Child>.grandChild>div>span:nth-child(2) {
    font-size: 0.75rem;
    font-weight: 600;
}

.productReviewContainer2Child>.grandChild>div {
    display: flex;
    gap: 0.3rem;
}

.productReviewContainer3 {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-column: 4/5;
    grid-row: 1/3;
}


.productReviewContainer3>span {
    color: #64676A;
    word-wrap: break-word;
}

.productReviewContainer3>button {
    padding: 0.8rem;
    background-color: var(--background-color);
    border: 0.07rem solid #64676A;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
}



@media(max-width: 500px) {

    .productReviewContainer1 {
        grid-column: 1/3;
        grid-row: 2/3;
    }

    .productReviewContainer2 {
        grid-column: 1/5;
        grid-row: 1/2;
    }

    .productReviewContainer3 {
        grid-column: 3/5;
        grid-row: 2/3;
    }
}


