.heroTop {
    display: flex;
    height: 3rem;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 500;
    word-wrap: break-word;
    position: fixed;
    background-color: rgb(244, 244, 244);
    top: 80px;
    left: 0px;
    right: 0px;
    z-index: 9999;
    font-size: 0.9rem;
}

.heroTop>div {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.heroTop>div>img {
    width: 0.9rem;
}

.industry {
    cursor: pointer;
}

.industry-dropdown {
    position: absolute;
    top: 70%;
    background-color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
    padding: 2rem;
    font-weight: 500;
    border-radius: 0.5rem;
}


.industry-dropdown span {
    display: block;
    padding: 0.4rem 0.5rem;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s ease;
}

.industry-dropdown span:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
}

