.profileContent {
    margin-top: calc(80px + 20px);
    margin-left: 5vw;
    margin-right: 5vw;
}

.profileSection {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.profileSectionLeft {
    /* height: calc(100% - 100px); */
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    margin-top: 100px;
    margin-left: 5vw;
    gap: 15px;
}

.profileSectionLeft .para {
    font-size: 1rem;
    border: 1px solid black;
    padding: 0.7rem;
    border-radius: 0.5rem;
    background-color: var(--orange);
    word-wrap: break-word;
}
