.navbar {
    height: 80px;
    width: 100%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    background-color: var(--background-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 99991;
}

.logoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    margin-left: 5vw;
}

.logo {
    height: 100%;
}

.logoText {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    margin-bottom: 5%;
}

.logoText>span:nth-child(1) {
    color: #20276B;
    font-size: 1.5rem;
    font-weight: 700;
}

.logoText>span:nth-child(2) {
    color: #FFB21D;
    font-size: 1.5rem;
    font-weight: 700;
}

.navBtn {
    display: flex;
    flex-direction: row;
    gap: 2vw;
    font-weight: 500;
    font-size: 1.2rem;
}

.navAdviceBtn {
    background-color: #3BAE8C;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0.6rem;
    padding: 0.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: var(--background-color);
    text-align: center;
    cursor: pointer;
}


.navSellBtn {
    background-color: #0057FF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0.6rem;
    padding: 0.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: var(--background-color);
    text-align: center;
    cursor: pointer;
}

.navProfile {
    margin-right: 5vw;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.navProfile>img {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}


.dropdown {
    display: none;
    position: absolute;
    top: 82%;
    right: 0vw;
    background-color: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
    padding: 20px;
    font-weight: 600;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.dropdown span {
    display: block;
    padding: 8px 10px;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s ease;
}

.dropdown span:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
}

.navProfile:hover+#dropdown {
    display: block;
}

.dropdown:hover {
    display: block;
}



@media(max-width: 600px) {
    .navAdviceBtn {
        display: none;
    }

    .navSellBtn {
        display: none;
    }
}



