.productLists {
    margin-top: calc(80px + 40px);
    margin-bottom: 50px;
}

.productContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.productList {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    margin-left: 5vw;
    margin-right: 5vw;
    border: 0.1rem solid rgb(206, 206, 206);
    border-radius: 1rem;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.20);
    padding: 1rem 1.5rem 1rem 1.5rem;
}

.productList > .left {
    width: 14%;
}

.productImg {
    width: 80%;
    cursor: pointer;
}

.productList>.mid {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 63%;

}

.productRating {
    background-color: #3BAE8C;
    color: var(--background-color);
    padding: 0.2rem 1rem 0.2rem 1rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
}

.productName {
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
}

.productBrand {
    font-size: 1rem;
}

.productDesc {
    line-height: 1.3rem;
    font-size: 0.9rem;
    letter-spacing: 1px;
    word-wrap: break-word;
}

.readMore {
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
}

.productList>.right {
    display: flex;
    flex-direction: column;
    gap: 100px;
    justify-content: space-between;
    align-items: center;
    width: 27%;
}

.productDemoBtn {
    background-color: #1A73E8;
    font-size: 0.8rem;
}

.productPrice {
    font-size: 1.1rem;
    font-weight: 600;
}


@media (max-width: 600px) {

    .productList {
        flex-direction: column;
        gap: 0.4rem;
    }

    .productList > .right {
        gap: 1rem;
        margin-top: 1rem;
    }

    .productList > .mid {
        width: 90%;
        margin-top: 1rem;
        text-align: center;
    }

    .productRating {
        display: none;
    }
}