.writeReview {
    display: grid;
    grid-template-columns: 4.5fr 5.5fr;
    margin-left: 8vw;
    margin-right: 8vw;
    margin-top: 40px;
    margin-bottom: 40px;
}


.writeReviewLeft {
    width: 80%;
}

.writeReviewRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-left: 5vw;
}

.writeReviewRight>span:nth-child(1) {
    font-size: 2rem;
    font-weight: 500;
    color: var(--color);
    word-wrap: break-word;
}


.writeReviewRight>span:nth-child(3) {
    font-size: 2.3rem;
    font-weight: 700;
    color: #279EB8;
    word-wrap: break-word;
}

.writeReviewRight>button {
    color: #279EB8;
    background-color: var(--background-color);
    border: 0.15rem solid #279EB8;
    border-radius: 0.7rem;
    padding: 1rem;
    font-weight: 600;
    margin-top: 30px;
    cursor: pointer;
}

