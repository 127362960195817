.productPlansMainContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 4rem;
    padding: 3rem 3rem 3rem 3rem;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
}


.plansMainContainer {
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2%;
}


.plansContainer {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    border-radius: 0.2rem;
}

.plansTextContainer {
    padding: 1rem;
    overflow-y: auto;
    max-height: 100px;
}

.plansTextContainer::-webkit-scrollbar {
    width: 6px;
    /* Adjust the width as needed */
}

.plansTextContainer::-webkit-scrollbar-thumb {
    background-color: #b4b4b4;
    /* Color of the scrollbar thumb */
    border-radius: 3px;
    /* Rounded corners for the thumb */
}

.plansTextContainer {
    display: flex;
    flex-direction: column;
}

.plansTitle {
    background-color: #1FBC96;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    border-top-right-radius: 0.2rem;
    border-top-left-radius: 0.2rem;
    padding: 1.5rem 1.5rem;
    text-align: center;
    word-wrap: break-word;
    min-height: 3.5rem;
}


.plansText {
    font-size: 0.75rem;
    color: rgb(42, 42, 42);
    padding: 0.2rem 0.5rem 0.2rem 0.8rem;
}


.plansBottomText {
    font-size: 0.6rem;
    color: #888b92;
}

.plansBottomContainer {
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    background-color: #F5F7FD;
}

.plansPrice {
    font-weight: 500;
    font-size: 0.9rem;
}

.plansBottomContainer>button {
    background-color: #FFB21D;
    border: none;
    padding: 0.7rem 2rem;
    border-radius: 0.5rem;
    color: #ffffff;
    cursor: pointer;
}


@media (max-width: 700px) {
    .plansMainContainer {
        grid-template-columns: 1fr 1fr;
    }
}


