.productPricing {
    display: grid;
    grid-template-columns: 6fr 4fr;
    height: 350px;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 50px;
    background-color: var(--background-color);
}

.productPricingLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: var(--color);
    gap: 25px;
}

.productPricingRight {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.productPricingRight > img {
    width: 75%;
}

.productPricingTitle {
    font-size: 2rem;
    font-weight: 600;
    word-wrap: break-word;
}

.productPricingSubtitle {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 1.8rem;
    word-wrap: break-word;
}

.productPricingBtn {
    background-color: var(--color);
    color: var(--background-color);
    font-size: 1.2rem;
    font-weight: 600;
    border: none;
    border-radius: 0.5rem;
    padding: 0.7rem 1.2rem 0.7rem 1.2rem;
    cursor: pointer;
}

