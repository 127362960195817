.productFaq {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 50px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    padding: 3vw 5vw 3vw 5vw;
}

.productFaq>.title {
    font-size: 2rem;
    font-weight: 600;
}

.productFaq>.mainContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.productFaq>.mainContainer>.left {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.productFaq>.mainContainer>.right {
    display: flex;
    flex-direction: column;
    gap: 20px;
    word-wrap: break-word;
    background-color: rgb(242, 242, 242);
    padding: 1rem;
    border-radius: 1rem;
    font-weight: 500;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
    letter-spacing: 0.5px;
}

.productFaq>.mainContainer>.right>span:nth-child(1) {
    font-weight: 600;
    font-size: 1.1rem;
}

.productFaq>.mainContainer>.right>span:nth-child(2) {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.3rem;
    color: rgb(35, 35, 35);
}


.que {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1vw;
    margin-right: 4vw;
    padding-right: 1vw;
    padding-top: 20px;
    padding-bottom: 20px;
    cursor: pointer;
}

.que>span:nth-child(1) {
    width: 80%;
}

.toggle {
    transform: rotate(270deg);
    width: 1rem;
    height: 1rem;
}

.active {
    background-color: rgb(234, 234, 234);
    border-radius: 0.5rem;
}


