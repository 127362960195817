.productServiceTitle {
    display: flex;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 20px;
    margin-bottom: 50px;
    justify-content: space-between;
}

.productServiceTitle>div {
    display: flex;
    align-items: center;
    gap: 1vw;
    border: 0.05rem black solid;
    border-radius: 2rem;
    padding: 0.5rem 0.6rem 0.5rem 0.6rem;
    font-size: 0.8rem;
    font-weight: 500;
}

.productServiceTitle>div>img {
    width: 2rem;
}

